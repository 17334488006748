@font-face {
  font-family: 'Deluce';
  src: url('./fonts/Deluce.ttf') format('truetype');
}

@font-face {
  font-family: 'Deluce';
  src: url('./fonts/Deluce.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: #f5f5dc;
  color: #333;
}

.App {
  text-align: center;
}

.App-logo {
  height: 50px;
}

.App-header {
  background-color: #d8d8bc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Naratif Condensed';
  font-weight: bolder;
  font-style: italic;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.App-nav {
  display: flex;
  align-items: center;
  transition: max-height 0.3s ease-in-out;
}

.App-nav a {
  color: #333;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 16px;
}

.App-nav a:hover {
  background-color: #575757;
  color: white;
}

.hamburger {
  display: none;
  font-size: 30px;
  cursor: pointer;
}

.App-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #333;
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in;
}

.App-container h1 {
  font-size: 60px;
  font-weight: 500;
  font-family: 'Deluce', sans-serif;
  margin-bottom: -10px;
}

.App-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-form label {
  margin: 10px 0;
  border-radius: 20px;
}

.App-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  width: 100%;
  color: #606060;
}

.message {
  margin: 20px 0;
  font-size: 16px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .App-nav {
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
  }

  .App-nav.open {
    max-height: 200px;
  }

  .hamburger {
    display: block;
  }
}
